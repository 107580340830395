import Cta from "../common/cta/cta";
import CategoryArea from "./category-area";
import HeroArea from "./hero-area";
import Pricing from "./pricing";
import HowItWorks from "./How-it-works";
import "./pricing.css";
const index = () => {
  return (
    <>
      {/* hero start */}
      <HeroArea />
      {/* hero end */}

      {/* category start */}
      <CategoryArea />
      {/* category end */}

      {/* banner start */}
      {/* <BannerArea /> */}
      {/* banner end */}

      {/* 
      {<CareersPath />} */}
      {/* <HowItWorks /> */}
      {/* event start */}
      {/* <EventArea /> */}
      {/* event end */}
      {/* Commented till we create blogs and webinars */}
      {/* {<MixEvents/>}  */}

      <div className="white-bg">
        <div style={{ paddingTop: "50px" }} className="description">
          Take free communication challenge
        </div>
        {/* <div
          style={{
            fontSize: "22px",
            marginBottom: "0px",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
          className="description"
        >
          Explore the Artificial Intelligence (AI) led Job-preparation &
          placement experience
        </div>
        <br /> */}
        <div className="image-section">
          <img
            width="300px"
            style={{ borderRadius: "230px" }}
            src="https://lifeskillsbharat.com/images/avatar2.png"
            alt="Illustration"
          />
        </div>
        <div style={{ paddingBottom: "10px" }} className="image-section">
          <a
            className="ctabtn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://communicationtest.lifeskillsbharat.com/"
          >
            Start
          </a>
        </div>
      </div>

      {/* pricing start */}
      <div style={{ marginTop: 40 }}>
        <Pricing />
      </div>
      {/* pricing end */}

      {/* cta start */}
      {/* <Cta /> */}

      {/* cta end */}
    </>
  );
};

export default index;
