import { Link } from "react-router-dom";
import "./pricing.css";
const pricing_data = [
  {
    id: 1,
    pricing_plan: [
      {
        price_title: "Communicate for Impact",
        price_subtitle:
          "Speak with confidence and present your ideas clearly using our latest AI technology",
        price: 2499,
        navige_link: "/main-payment-page",
        ActivatePackage: "COMMUNICATEIMPACT",
        price_features: [
          "Verbal communication",
          "Non-verbal communication",
          "Group discussion",
          "Public speaking",
          "Business English and emails",
          "Art of presentation",
        ],
      },

      {
        price_title: "Personal Branding",
        price_subtitle:
          "Become self-aware and learn the right tools to excel in your career",
        price: 2299,
        navige_link: "/main-payment-page",
        ActivatePackage: "PERSONALBRANDING",
        price_features: [
          "SWOT analysis",
          "Goal setting",
          "Time management",
          "Etiquettes and grooming",
          "Collaboration",
          "Leadership",
        ],
      },

      {
        price_title: "Aptitude and Critical Thinking",
        price_subtitle:
          "Sharpen cognitive abilities and excel in Olympiads and competitive exams",
        price: 2199,
        navige_link: "/main-payment-page",
        ActivatePackage: "APTITUDECRITICAL",
        price_features: [
          "Numerical ability",
          "Verbal ability",
          "Data interpretation",
          "Logical reasoning",
          "Spatial reasoning",
          "Perceptual speed",
        ],
      },

      {
        price_title: "Technology – Robotics, AI, and Coding",
        price_subtitle:
          "Discover the innovator in you by learning the latest technologies through live projects",
        price: 2599,
        navige_link: "/main-payment-page",
        ActivatePackage: "TECHNOLOGYAI",
        price_features: [
          "Block programming",
          "Robotics",
          "3D modeling",
          "Apps and games",
          "AI and ML",
          "Virtual reality",
        ],
      },

      {
        price_title: "Career Counseling and Mental Wellness",
        price_subtitle:
          "Analyze your strengths and interests to discover your ideal career path and build mental resilience",
        price: 1999,
        navige_link: "/main-payment-page",
        ActivatePackage: "CAREERCOUNSELING",
        price_features: [
          "Personality test",
          "Interest alignment",
          "Aptitude assessment",
          "Mental resilience",
          "Stress management",
          "Personal counseling",
        ],
      },
    ],
  },
];

const Pricing = () => {
  return (
    <>
      <section className="price__area">
        <div className="row">
          <div className="row">
            <div className="image-section">
              <div className="section__title-wrapper mb-60 text-center">
                <h2 className="section__title">
                  Our{" "}
                  <span className="yellow-bg yellow-bg-big">
                    Programs
                    <img src="/assets/img/shape/yellow-bg.png" alt="" />
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="price__tab-content">
                <div className="tab-content" id="nav-tabContent">
                  <div style={{ marginLeft: 15 }} className="row">
                    {pricing_data.map((item, index) => (
                      <div className="row">
                        {item.pricing_plan.map((price, index) => (
                          <div
                            style={{ minWidth: "20%" }}
                            key={index}
                            className="col-md-2"
                          >
                            <div
                              className="price__item grey-bg mb-30 p-relative"
                              style={{ padding: "20px", height: 450 }}
                            >
                              <div className="price__head">
                                <h4>{price.price_title}</h4>
                                <p>{price.price_subtitle}</p>
                              </div>
                              <div className="price__features mb-20 mt-20">
                                <ul>
                                  {price.price_features.map(
                                    (feature, index) => (
                                      <li key={index}>
                                        {!feature == "" ? (
                                          <>
                                            <i className="far fa-check"></i>
                                            {feature}
                                          </>
                                        ) : null}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>

                              {/* <Link
                                to="/main-payment-page"
                                state={{ data: price }}
                                className={`e-btn ${
                                  index === 3 ? "e-btn-4" : "e-btn-border"
                                }`}
                              >
                                Get Started
                              </Link> */}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
